<template>
  <div class="content-container flex-row pb-0 pt-20">
    <TopLevelLeftNav />

    <div class="page">
      <div class="subpage-banner"></div>
      <div class="subpage-content">
        <section>
          <div class="content-band">
            <div class="pagehead-title">
              <h1>Image <br />Utilities</h1>
              <div class="pagehead-link">
                <a href=""
                  ><img src="/html-components/images/svg/link.svg" alt=""
                /></a>
              </div>
            </div>
            <p>
              Image utilities consist of the css properties object-fit and
              object position that control how an image is displayed within its
              container.
            </p>
          </div>
        </section>

        <div class="divider max-w1200"></div>

        <div class="flex-sidebar flex-wrap">
          <div class="container max-w1000">
            <section id="object-fit">
              <div class="content-band" id="">
                <h2>Object Fit</h2>
                <p class="mb-30">
                  The CSS object-fit property is used to specify how an
                  &#x3C;img&#x3E; or &#x3C;video&#x3E; should be resized to fit
                  its container.
                </p>

                <div
                  class="flex flex-col max-w800 white"
                >
                  <div class="flex">
                    <div class="container plr-30 ptb-20 basis-50 gray-250">
                      Utility class
                    </div>
                    <div class="container plr-30 ptb-20 basis-50 gray-250">
                      Properties
                    </div>
                  </div>

                  <div class="flex border-bottom">
                    <div
                      class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100"
                    >
                      .object-contain
                    </div>
                    <div
                      class="container plr-30 ptb-20 basis-50 flex align-center min-w100"
                    >
                      object-fit: contain;
                    </div>
                  </div>

                  <div class="flex border-bottom">
                    <div
                      class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100"
                    >
                      .object-cover
                    </div>
                    <div
                      class="container plr-30 ptb-20 basis-50 flex align-center min-w100"
                    >
                      object-fit: cover;
                    </div>
                  </div>

                  <div class="flex">
                    <div
                      class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100"
                    >
                      .object-fill
                    </div>
                    <div
                      class="container plr-30 ptb-20 basis-50 flex align-center min-w100"
                    >
                      object-fit: fill;
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section id="object-contain">
              <div class="content-band" id="">
                <h2>Object Contain</h2>
                <p>
                  The image keeps its aspect ratio, but is resized to fit within
                  the given dimension applied to the image.
                </p>

                <div class="container max-w800">
                  <div class="red-100 line-height-none p-20">
                    <div class=" width-full pattern-diagonal-lines-sm">
                      <img
                        class="img-contain max-h300"
                        src="../../assets/images/pic-02.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="gray-800 p-20 code font-white">
                    &#x3C;<span class="code-purple">div </span
                    ><span class="code-blue">class</span>="<span
                      class="code-green"
                      >container width-full</span
                    >"&#x3E;
                    <br />
                    <span class="pl-10"
                      >&#x3C;<span class="code-purple">img</span>
                      <span class="code-blue">class</span>="<span
                        class="code-green"
                        >img-contain h300</span
                      >" <span class="code-green">. . . </span>&#x3E;</span
                    >
                    <br />
                    &#x3C;/<span class="code-purple">div</span>&#x3E;
                  </div>
                </div>
              </div>
            </section>

            <section id="object-cover">
              <div class="content-band" id="">
                <h2>Object Cover</h2>
                <p>
                  The image keeps its aspect ratio and fills the given
                  dimension. The image will be clipped to fit:
                </p>

                <div class="container max-w800">
                  <div class="red-100 line-height-none p-20">
                    <div class=" width-full pattern-diagonal-lines-sm">
                      <img
                        class="img-cover max-h300"
                        src="../../assets/images/pic-02.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="gray-800 p-20 code font-white">
                    &#x3C;<span class="code-purple">div </span
                    ><span class="code-blue">class</span>="<span
                      class="code-green"
                      >container width-full</span
                    >"&#x3E;
                    <br />
                    <span class="pl-10"
                      >&#x3C;<span class="code-purple">img</span>
                      <span class="code-blue">class</span>="<span
                        class="code-green"
                        >img-cover h300</span
                      >" <span class="code-green">. . . </span>&#x3E;</span
                    >
                    <br />
                    &#x3C;/<span class="code-purple">div</span>&#x3E;
                  </div>
                </div>
              </div>
            </section>

            <section id="object-fill">
              <div class="content-band" id="">
                <h2>Object Fill</h2>
                <p>
                  The image is resized to fill the given dimension. If
                  necessary, the image will be stretched or squished to fit:
                </p>

                <div class="container max-w800">
                  <div class="red-100 line-height-none p-20">
                    <div class=" width-full pattern-diagonal-lines-sm">
                      <img
                        class="img-fill max-h300"
                        src="../../assets/images/pic-02.jpg"
                        alt=""
                      />
                    </div>
                  </div>

                  <div class="gray-800 p-20 code font-white">
                    &#x3C;<span class="code-purple">div </span
                    ><span class="code-blue">class</span>="<span
                      class="code-green"
                      >container width-full</span
                    >"&#x3E;
                    <br />
                    <span class="pl-10"
                      >&#x3C;<span class="code-purple">img</span>
                      <span class="code-blue">class</span>="<span
                        class="code-green"
                        >img-fill h300</span
                      >" <span class="code-green">. . . </span>&#x3E;</span
                    >
                    <br />
                    &#x3C;/<span class="code-purple">div</span>&#x3E;
                  </div>
                </div>
              </div>
            </section>

            <section id="object-position">
              <div class="content-band" id="">
                <h2>Object Position</h2>
                <p>
                  The CSS object-position property is used to specify how an
                  &#x3C;img&#x3E; or &#x3C;video&#x3E; should be positioned
                  within its container.
                </p>

                <div
                  class="flex flex-col max-w800 white mtb-30"
                >
                  <div class="flex">
                    <div class="container plr-30 ptb-20 basis-50 gray-250">
                      Utility class
                    </div>
                    <div class="container plr-30 ptb-20 basis-50 gray-250">
                      Properties
                    </div>
                  </div>

                  <div class="flex border-bottom">
                    <div
                      class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100"
                    >
                      .img-top
                    </div>
                    <div
                      class="container plr-30 ptb-20 basis-50 flex align-center min-w100"
                    >
                      object-position: top;
                    </div>
                  </div>

                  <div class="flex border-bottom">
                    <div
                      class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100"
                    >
                      .img-bottom
                    </div>
                    <div
                      class="container plr-30 ptb-20 basis-50 flex align-center min-w100"
                    >
                      object-position: bottom;
                    </div>
                  </div>

                  <div class="flex border-bottom">
                    <div
                      class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100"
                    >
                      .img-left
                    </div>
                    <div
                      class="container plr-30 ptb-20 basis-50 flex align-center min-w100"
                    >
                      object-position: left;
                    </div>
                  </div>

                  <div class="flex border-bottom">
                    <div
                      class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100"
                    >
                      .img-right
                    </div>
                    <div
                      class="container plr-30 ptb-20 basis-50 flex align-center min-w100"
                    >
                      object-position: right;
                    </div>
                  </div>

                  <div class="flex">
                    <div
                      class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100"
                    >
                      .img-center
                    </div>
                    <div
                      class="container plr-30 ptb-20 basis-50 flex align-center min-w100"
                    >
                      object-position: center;
                    </div>
                  </div>
                </div>

                <div class="container max-w800 mtb-30">
                  <div class="font-18 ptb-20 semibold">
                    Object Position - Top <br />
                    <span class="font-16 regular">.img-top</span>
                  </div>

                  <div class="red-100 line-height-none p-20">
                    <div
                      class="width-full pattern-diagonal-lines-sm flex justify-center"
                    >
                      <img
                        class="img-top max-h300 max-w400"
                        src="../../assets/images/pic-02.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="gray-800 p-20 font-white code">
                    &#x3C;<span class="code-purple">div </span
                    ><span class="code-blue">class</span>="<span
                      class="code-green"
                      >container</span
                    >"&#x3E;
                    <br />
                    <span class="pl-10"
                      >&#x3C;<span class="code-purple">img</span>
                      <span class="code-blue">class</span>="<span
                        class="code-green"
                        >img-top h300 w400</span
                      >" <span class="code-green">. . . </span>&#x3E;</span
                    >
                    <br />
                    &#x3C;/<span class="code-purple">div</span>&#x3E;
                  </div>
                </div>

                <div class="container max-w800 mtb-30">
                  <div class="font-18 ptb-20 semibold">
                    Object Position - Bottom <br />
                    <span class="font-16 regular">.img-bottom</span>
                  </div>

                  <div class="red-100 line-height-none p-20">
                    <div
                      class="width-full pattern-diagonal-lines-sm flex justify-center"
                    >
                      <img
                        class="img-bottom h300 max-w400"
                        src="../../assets/images/pic-02.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="gray-800 p-20 font-white code">
                    &#x3C;<span class="code-purple">div </span
                    ><span class="code-blue">class</span>="<span
                      class="code-green"
                      >container</span
                    >"&#x3E;
                    <br />
                    <span class="pl-10"
                      >&#x3C;<span class="code-purple">img</span>
                      <span class="code-blue">class</span>="<span
                        class="code-green"
                        >img-bottom h300 w400</span
                      >" <span class="code-green">. . . </span>&#x3E;</span
                    >
                    <br />
                    &#x3C;/<span class="code-purple">div</span>&#x3E;
                  </div>
                </div>

                <div class="container max-w800 mtb-30">
                  <div class="font-18 ptb-20 semibold">
                    Object Position - Left <br />
                    <span class="font-16 regular">.img-left</span>
                  </div>

                  <div class="red-100 line-height-none p-20">
                    <div
                      class="width-full pattern-diagonal-lines-sm flex justify-center"
                    >
                      <img
                        class="img-left max-h300 max-w400"
                        src="../../assets/images/pic-02.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="gray-800 p-20 font-white code">
                    &#x3C;<span class="code-purple">div </span
                    ><span class="code-blue">class</span>="<span
                      class="code-green"
                      >container</span
                    >"&#x3E;
                    <br />
                    <span class="pl-10"
                      >&#x3C;<span class="code-purple">img</span>
                      <span class="code-blue">class</span>="<span
                        class="code-green"
                        >img-left h300 w400</span
                      >" <span class="code-green">. . . </span>&#x3E;</span
                    >
                    <br />
                    &#x3C;/<span class="code-purple">div</span>&#x3E;
                  </div>
                </div>

                <div class="container max-w800 mtb-30">
                  <div class="font-18 ptb-20 semibold">
                    Object Position - Right <br />
                    <span class="font-16 regular">.img-right</span>
                  </div>

                  <div class="red-100 line-height-none p-20">
                    <div
                      class="width-full pattern-diagonal-lines-sm flex justify-center"
                    >
                      <img
                        class="img-right max-h300 max-w400"
                        src="../../assets/images/pic-02.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="gray-800 p-20 font-white code">
                    &#x3C;<span class="code-purple">div </span
                    ><span class="code-blue">class</span>="<span
                      class="code-green"
                      >container</span
                    >"&#x3E;
                    <br />
                    <span class="pl-10"
                      >&#x3C;<span class="code-purple">img</span>
                      <span class="code-blue">class</span>="<span
                        class="code-green"
                        >img-right h300 w400</span
                      >" <span class="code-green">. . . </span>&#x3E;</span
                    >
                    <br />
                    &#x3C;/<span class="code-purple">div</span>&#x3E;
                  </div>
                </div>

                <div class="container max-w800 mtb-30">
                  <div class="font-18 ptb-20 semibold">
                    Object Position - Center <br />
                    <span class="font-16 regular">.img-center</span>
                  </div>

                  <div class="red-100 line-height-none p-20">
                    <div
                      class="width-full pattern-diagonal-lines-sm flex justify-center"
                    >
                      <img
                        class="img-center max-h300 max-w400"
                        src="../../assets/images/pic-02.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="gray-800 p-20 font-white code">
                    &#x3C;<span class="code-purple">div </span
                    ><span class="code-blue">class</span>="<span
                      class="code-green"
                      >container</span
                    >"&#x3E;
                    <br />
                    <span class="pl-10"
                      >&#x3C;<span class="code-purple">img</span>
                      <span class="code-blue">class</span>="<span
                        class="code-green"
                        >img-right h300 w400</span
                      >" <span class="code-green">. . . </span>&#x3E;</span
                    >
                    <br />
                    &#x3C;/<span class="code-purple">div</span>&#x3E;
                  </div>
                </div>
              </div>
            </section>
          </div>

          <StickyTOC />
        </div>
      </div>

      <SubFooter />
    </div>
  </div>
</template>

<script>
import StickyTOC from "../StickyTOC";
import TopLevelLeftNav from "../../components/TopLevelLeftNav";
import SubFooter from "../../components/SubFooter";
export default {
  name: "Images",
  components: {
    TopLevelLeftNav,
    StickyTOC,
    SubFooter,
  },
};
</script>
